<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Entry Exit Ip Port Master
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Entry Exit Ip Port Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                    data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="add"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.gate_name }}</td>
                                <td>{{ li.entry_ip }}</td>
                                <td>{{ li.entry_port }}</td>
                                <td>{{ li.exit_ip }}</td>
                                <td>{{ li.exit_port }}</td>
                                <td>{{ li.created_at }}</td>
                                <td>{{ li.updated_at }}</td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="edit" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDes(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="trash-2" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="9" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          method="post"
          @submit.prevent="submitForm"
        >
          <input type="hidden" name="id" :v-modal="id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[isShow='', isDisplay='none']"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalName }} Entry Exit Ip-Port
            </h4>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="mb-1">
              <Select2 v-model="gate_id" :options="$store.state.entryExitIpPortData" @change="myChangeEvent($event)" :settings="{ placeholder: 'Choose Gate' }" @select="mySelectEvent($event)"/>
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: gateIdError}"
              >
                Please Choose Gate name.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Entry Ip</label
              >
              <input
                type="text"
                class="form-control"
                name="entry_ip"
                v-model="entry_ip"
                placeholder="Entry Ip"
                @keyup="
                  entry_ip.length > 0
                    ? [(entryIpError = 'none'), (disabled = false)]
                    : (entryIpError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: entryIpError}"
              >
                Please enter entry ip.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Entry Port</label
              >
              <input
                type="number"
                class="form-control"
                name="entry_port"
                v-model="entry_port"
                placeholder="Entry Port"
                @keyup="
                  entry_port.toString().length > 0
                    ? [(entryPortError = 'none'), (disabled = false)]
                    : (entryPortError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: entryPortError}"
              >
                Please enter entry Port.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Exit Ip</label
              >
              <input
                type="text"
                class="form-control"
                name="exit_ip"
                v-model="exit_ip"
                placeholder="Exit Ip"
                @keyup="
                  exit_ip.length > 0
                    ? [(exitIpError = 'none'), (disabled = false)]
                    : (exitIpError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: exitIpError}"
              >
                Please enter exit ip.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Exit Port</label
              >
              <input
                type="number"
                class="form-control"
                name="exit_port"
                v-model="exit_port"
                placeholder="Exit Port"
                @keyup="
                  exit_port.toString().length > 0
                    ? [(exitPortError = 'none'), (disabled = false)]
                    : (exitPortError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: exitPortError}"
              >
                Please enter Exit Port.
              </div>
            </div>
            
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[isShow='', isDisplay='none']"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Select2 from 'vue3-select2-component';

export default {
  name: "Designation",
  components: {
    Pagination: LaravelVuePagination,
    Select2
  },
  data() {
    return {
      id: null,
      gate_id: "",
      entry_ip: "",
      entry_port: "",
      exit_ip: "",
      exit_port: "",
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      gateIdError: "none",
      entryIpError: "none",
      entryPortError: "none",
      exitIpError: "none",
      exitPortError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem('auth_token')
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Gate Name",
      "Entry Ip",
      "Entry Port",
      "Exit Ip",
      "Exit Port",
      "Created Date",
      "Updated Date",
      "Action",
    ];
    this.$store.dispatch('getEntryExitIpPortList');
    this.getResults();
  },
  methods: {
    mySelectEvent({ id }) {
      this.gate_id = id;
      this.disabled = false;
    },
    async add() {
      (this.id = null),
      (this.gate_id = ""),
      (this.entry_ip = ""),
      (this.entry_port = ""),
      (this.exit_ip = ""),
      (this.exit_port = ""),
      (this.response = "");
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL+"/ip_port_master?page=" + page, 
        {
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            "Authorization": `Bearer ${this.token}`
          }
        })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    async submitForm() {
      this.disabled = true;
      if (this.gate_id.length === 0) {
        this.gateIdError = "block";
      } else if (this.entry_ip.length === 0) {
        this.entryIpError = "block";
      } else if(this.entry_port.toString().length === 0) {
        this.entryPortError = "block";
      } else if(this.exit_ip.length === 0) {
        this.exitIpError = "block";
      } else if(this.exit_port.toString().length === 0) {
        this.exitPortError = "block";
      } else {

        this.gateIdError = "none";
        this.entryIpError = "none";
        this.entryPortError = "none";
        this.exitIpError = "none";
        this.exitPortError = "none";

        if (this.id) {
          await axios
          .put(process.env.VUE_APP_API_URL+"/ip_port_master/" + this.id,
            {
              gate_id: this.gate_id,
              entry_ip: this.entry_ip,
              entry_port: this.entry_port,
              exit_ip: this.exit_ip,
              exit_port: this.exit_port,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
        } else {
          await axios
            .post(process.env.VUE_APP_API_URL+"/ip_port_master",
            {
              gate_id: this.gate_id,
              entry_ip: this.entry_ip,
              entry_port: this.entry_port,
              exit_ip: this.exit_ip,
              exit_port: this.exit_port,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
            )
            .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
        }
      }
    },
    deleteDes(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL+"/ip_port_master/" + id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id) {
      this.gateIdError= "none"
      this.entryIpError= "none"
      this.entryPortError= "none"
      this.exitIpError= "none"
      this.exitPortError= "none"
      this.disabled= false
      console.log('id: ', id);
      console.log('link: ', process.env.VUE_APP_API_URL+"/ip_port_master" +(id ? "/" + id : ""));
      const output = await axios.get(process.env.VUE_APP_API_URL+"/ip_port_master" +(id ? "/" + id : ""),
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              });
              
      this.id = output.data.data?.id;
      this.gate_id = output.data.data?.gate_id;
      this.entry_ip = output.data.data?.entry_ip;
      this.entry_port = output.data.data?.entry_port;
      this.exit_ip = output.data.data?.exit_ip;
      this.exit_port = output.data.data?.exit_port;
      this.modalName = "Edit";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL+"/ip_port_master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = ""
            this.isDisplay = "none"
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
<style></style>
